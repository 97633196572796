import { Component } from 'vue-property-decorator'

import { SET_META } from 'booksprout-app'
import { ArcWithStatsDto } from 'booksprout'

import { BaseMixin } from 'src/components/mixins/base'

import IconApple from 'src/components/svg-icons/IconApple.vue'
import IconAmazon from 'src/components/svg-icons/IconAmazon.vue'
import IconAndroid from 'src/components/svg-icons/IconAndroid.vue'
import WebsiteButton from 'src/components/WebsiteButton.vue'
import WebsiteCard from 'src/components/WebsiteCard.vue'
import WebsiteMember from 'src/components/WebsiteMember.vue'
import WebsiteOrSeparator from 'src/components/WebsiteOrSeparator.vue'
import WebsiteRating from 'src/components/WebsiteRating.vue'
import WebsiteReview from 'src/components/WebsiteReview.vue'
import WebsiteReviewCopy from 'src/components/WebsiteReviewCopy.vue'
import WebsiteReviewPopup from 'src/components/WebsiteReviewPopup.vue'
import WebsiteSpinner from 'src/components/WebsiteSpinner.vue'
import WebsiteStatsBox from 'src/components/WebsiteStatsBox.vue'

@Component({
  components: {
    IconApple,
    IconAmazon,
    IconAndroid,
    WebsiteButton,
    WebsiteCard,
    WebsiteMember,
    WebsiteOrSeparator,
    WebsiteRating,
    WebsiteReview,
    WebsiteReviewCopy,
    WebsiteReviewPopup,
    WebsiteSpinner,
    WebsiteStatsBox
  }
})
export default class WebsiteReadersPage extends BaseMixin {
  showSpinner = false

  arcLiveCount = 4000
  reviewCount = 3800000

  totalReviewCopiesAvailable = 0
  topActiveReviewCopy: ArcWithStatsDto = new ArcWithStatsDto()

  created () {
    const title = 'An app to follow your favorite authors'
    const canonicalUrl = window.location.origin + this.websitePaths.readers

    void this.$store.dispatch(SET_META, {
      title: title,
      description: 'Stay up to date on newly released books and deals from your favorite authors!',
      ogTitle: title,
      ogUrl: canonicalUrl,
      ogImage: window.location.origin + '/cdn/website/honest-reviews.png',
      canonicalUrl
    })
  }

  mounted () {
    this.getWebsiteStats().then(stats => {
      this.showSpinner = false
      this.arcLiveCount = stats.arcLiveCount
      this.reviewCount = stats.arcReviewCount
      this.totalReviewCopiesAvailable = stats.arcActiveCount < 100 ? 100 : Math.floor(stats.arcActiveCount / 100) * 100 // round to the closest 100
      this.topActiveReviewCopy = stats.topArc
    })
  }
}
