import { Component, Prop } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'
import IconCalendar from './svg-icons/IconCalendar.vue'
import { ArcWithStatsDto, HasIndex } from 'booksprout'

@Component({
  components: {
    IconCalendar
  }
})
export default class WebsiteReviewCopy extends BaseMixin {
  @Prop({ type: Object }) readonly reviewCopy!: ArcWithStatsDto

  getBookProp (prop: string) {
    if (this.reviewCopy.book?.hasOwnProperty(prop)) {
      return (this.reviewCopy.book as HasIndex)[prop] || ''
    }

    return ''
  }
}
