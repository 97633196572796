import { Component } from 'vue-property-decorator'
import { BaseMixin } from './mixins/base'
import WebsiteMember from './WebsiteMember.vue'
import WebsiteRating from './WebsiteRating.vue'

@Component({
  components: {
    WebsiteMember,
    WebsiteRating
  }
})
export default class WebsiteReview extends BaseMixin {

}
